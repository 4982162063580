import React, { useState, useEffect } from "react";
import "./market.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import Environment from "../../utils/Environment";
import InfiniteScroll from "react-infinite-scroll-component";

function MarketUnclaimed({ search2, metaDataSearch, setLoader3, loader3 }) {
    const [allNFTSUnclaim, setAllNFTSUnclaim] = useState([]);
    const [page, setPage] = useState(1);
    const [loader, setLoader] =useState(false)
    const [hasMore, setHasMore] = useState(true);
    const [toatalSold,setTotalSold]=useState([])

    async function heighestBid(label, tld) {
        var data = JSON.stringify({
            query: `query MyQuery {
                bidCreateds(
                  orderBy: bidTime
                  where: {label: "${label}", tld: "${tld}"}
                  orderDirection: desc
                ) {
                  label
                  bidder
                  bidTime
                  amount
                  tld
                  tokenId
                  difference
                }
            }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: Environment.marketplaceGraph,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let res = await axios(config);
        return res?.data?.data?.bidCreateds[0]?.amount;
    }

    const fetchUnclaimedNFTs = async (page) => {
        const data = JSON.stringify({
            query: `query MyQuery {
        auctionCreateds(where: {isClaimed: false}, first: 200) {
          isClaimed
          mintAmount
          labelhash
          label
          tokenId
          tld
          startTime
          partnerId
          minter
          expiryTime
        }
      }`,
            variables: {},
        });

        const config = {
            method: "post",
            url: Environment.marketplaceGraph,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            let minted;
            if (toatalSold?.length > 0){
                minted = toatalSold || [];
            }else{
                const response = await axios(config);
                 minted = response?.data?.data?.auctionCreateds || [];
                setTotalSold(minted)
            }
           

            let start = (page - 1) * 12;
            let end = page * 12;

            let currentBatch = minted.slice(start, end);

            let promises = currentBatch.map(async (nft) => {
                let label = nft?.label;
                let tld = nft?.tld;
                let res = await heighestBid(label, tld);
                nft.HighestBid = res;
                return nft;
            });

            let fetchedNFTs = await Promise.all(promises);
            return fetchedNFTs;
        } catch (err) {
            console.error('Failed to load sold NFTs:', err);
            return [];
        }
    };

    const loadMore = async () => {
        setLoader(true);
        const newNFTs = await fetchUnclaimedNFTs(page);
        if (newNFTs.length < 10) {
            setHasMore(false);
        }
        setAllNFTSUnclaim((prevNFTs) => [...prevNFTs, ...newNFTs]);
        setPage((prevPage) => prevPage + 1);
        setLoader(false);
    };

    useEffect(() => {
        loadMore();
    }, []);

    return (
        <>
            {(allNFTSUnclaim.length < 1 && !loader) && (
                <div className={`centershowing-text text-center w-100 py-3`}>
                    <h3 style={{ color: '#FFF' }}>No Domain <span className="common">Found!</span> </h3>
                </div>
            )}
            <InfiniteScroll
                dataLength={allNFTSUnclaim.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
                    <img
                        width={35}
                        style={{
                            // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                        }}
                        src="\loader\loader.gif"
                        alt="loader"
                    />
                </div>}
                // endMessage={
                //     <p className="my-4 text-light" style={{ textAlign: 'center' }}>
                //         <b>No More Domains!</b> 
                //     </p>
                // }
            >
                <div className="cardsss">
                    {(search2 ? metaDataSearch : allNFTSUnclaim)?.map((elem, index) => {
                        let name = elem?.name || elem?.label;
                        const currentTime = Math.floor(Date.now() / 1000);
                        const expiryTime = elem?.expiryTime
                        let umclaimed = expiryTime < currentTime
                        return (
                            umclaimed && 
                            <div className="custom-cards" key={index}>
                                <Link to={{ pathname: `/place/${elem.tokenId}` }}>
                                    <div className="card">
                                     
                                        <h5 className="card-heading-1">Highest Bid</h5>
                                        <p className="card-heading-2 text-lowercase">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
  <rect x="0.183594" y="0.772461" width="46.2121" height="46.2121" rx="23.1061" fill="white" fill-opacity="0.05"/>
  <path d="M22.913 15.7245C27.115 15.7245 30.5403 19.0472 30.6984 23.2071L34.3846 25.4686C34.4947 24.8258 34.5518 24.1658 34.5518 23.4916C34.5518 17.0695 29.3414 11.8633 22.9122 11.8633C16.483 11.8633 11.2734 17.0703 11.2734 23.4925C11.2734 24.8829 11.5177 26.2171 11.9664 27.4521C12.4102 28.6771 13.0544 29.8054 13.8592 30.7997L17.1686 28.769L20.9847 26.4272V35.3359H24.7404V26.3553L28.6641 28.7632L31.9727 30.7939C32.7767 29.7996 33.42 28.6705 33.8629 27.4447L30.4815 25.3693L26.595 22.9846L22.9213 20.7305L22.913 20.7355L19.2476 22.9846L15.3471 25.3776C15.1988 24.7787 15.1202 24.1542 15.1202 23.5098C15.1202 19.2102 18.6093 15.7253 22.9122 15.7253L22.913 15.7245ZM22.913 25.2336L22.9205 25.2378L22.913 25.2427V25.2328V25.2336Z" fill="#FF0083"/>
</svg>
                                            {elem.HighestBid
                                                ? (parseInt(elem.HighestBid) / 1000000000000000000)?.toFixed(2)
                                                : "70"}{" "}
                                        </p>
                                        <div className="card-img-top-div">
                                            <span className="w-100 text-left set-text-font">
                                                {name?.substring(0, 13)}{name?.length > 13 && '(...)'}.{elem.tld}
                                            </span>
                                        </div>
                                        <div className="card-style-2 w-100">
                                            <h5 className="endedspan">Ended</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </InfiniteScroll>
        </>
    );
}

export default MarketUnclaimed;






// import React, { useState, useEffect } from "react";
// import "./market.scss";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Loader from "../../hooks/loader";
// import CheckExpire from "../../hooks/dataFetchers/checkExpiry";
// import CheckHigh from "../../hooks/dataFetchers/getHighestBid";
// import ClaimNft from "../../hooks/dataFetchers/checkClaim";
// import Countdown from "react-countdown";
// import Navbar from "../landing/header/Navbar";
// import Environment from "../../utils/Environment";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { useWeb3React } from "@web3-react/core";
// import { useRef } from 'react';
// function MarketSold({ search2, metaDataSearch, setLoader3 }) {
//     const [allNFTSUnclaim, setAllNFTSUnclaim] = useState([]);
//     async function heighestBid(label, tld) {
//         var data = JSON.stringify({
//             query: `query MyQuery {
//         bidCreateds(
//           orderBy: bidTime
//           where: {label: "${label}", tld: "${tld}"}
//           orderDirection: desc
//         ) {
//           label
//           bidder
//           bidTime
//           amount
//           tld
//           tokenId
//           difference
//         }
// }`,
//             variables: {},
//         });
//         var config = {
//             method: "post",
//             url: Environment.marketplaceGraph,
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             data: data,
//         };
//         let res = await axios(config);
//         return res?.data?.data?.bidCreateds[0]?.amount
//     }
//     const OnSold = async () => {
//         setLoader3(true); // Assume this is the only loader we need for starting the process

//         const data = JSON.stringify({
//             query: `query MyQuery {
//           auctionCreateds(  where: {isClaimed: true}) {
//               isClaimed
//               mintAmount
//               labelhash
//               label
//               tokenId
//               tld
//               startTime
//               partnerId
//               minter
//           }
//       }`,
//             variables: {},
//         });

//         const config = {
//             method: "post",
//             url: Environment.marketplaceGraph,
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             data: data,
//         };

//         try {
//             const response = await axios(config);
//             const minted = response?.data?.data?.auctionCreateds || [];
//             let promises = minted.map(async (nft) => {
//                 let label = nft?.label;
//                 let tld = nft?.tld;
//                 let res = await heighestBid(label, tld);
//                 nft.HighestBid = res;
//                 return nft;
//             });

//             let dumSold = await Promise.all(promises);
//             console.log('dumSold', dumSold);

//             setAllNFTSUnclaim(dumSold);
//         } catch (err) {
//             console.error('Failed to load sold NFTs:', err);
//         } finally {
//             setLoader3(false);
//         }
//     };
//     useEffect(() => {
//         OnSold()
//     }, [])
    
//     return (
//         <>
//             {allNFTSUnclaim.length < 1 &&
//                 <div className={`centershowing-text text-center w-100 py-3`}>
//                     <h3 style={{ color: '#FFF' }}>No Domain <span className="common">Found!</span> </h3>
//                 </div>
//             }
//             <div class="cardsss">
//                 {(search2 ? metaDataSearch : allNFTSUnclaim)?.map((elem, index) => {
//                     let name = elem?.name || elem?.label
//                     return (
//                         <div className="custom-cards">
//                             <Link to={{ pathname: `/place/${elem.tokenId}` }}>
//                                 <div className="card">
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="195" height="277" viewBox="0 0 195 277" fill="none" className='cardimg'>
//                                         <path d="M140.005 46.7178C190.547 46.7178 231.748 86.9204 233.65 137.251L277.988 164.613C279.313 156.837 280 148.851 280 140.694C280 62.991 217.327 0 139.995 0C62.6626 0 0 63.001 0 140.704C0 157.528 2.93793 173.671 8.33576 188.613C13.6738 203.435 21.422 217.086 31.1023 229.116L70.9088 204.546L116.81 176.213V284H161.985V175.342L209.181 204.476L248.977 229.045C258.648 217.016 266.386 203.355 271.714 188.523L231.041 163.412L184.293 134.559L140.105 107.287L140.005 107.347L95.9161 134.559L48.9988 163.512C47.2161 156.267 46.27 148.71 46.27 140.914C46.27 88.892 88.2376 46.7278 139.995 46.7278L140.005 46.7178ZM140.005 161.771L140.095 161.821L140.005 161.881V161.761V161.771Z" fill="white" fill-opacity="0.03" />
//                                     </svg>
//                                     <h5 className="card-heading-1">Highest Bid</h5>
//                                     <p className="card-heading-2 text-lowercase">
//                                         <img className="mytomiicon mr-1" src="\assets\eth-icon.svg" alt="img" />
//                                         {elem.HighestBid
//                                             ? (parseInt(elem.HighestBid) / 1000000000000000000)?.toFixed(2)
//                                             : "70"}{" "}
//                                     </p>
//                                     <div className="card-img-top-div">
//                                         <span className="w-100 text-left set-text-font">
//                                             {name?.substring(0, 13)}{name?.length > 13 && '(...)'}.{elem.tld}
//                                         </span>
//                                     </div>
//                                     <div className="card-style-2 w-100">
//                                         <h5 className="endedspan">Ended</h5>
//                                     </div>
//                                 </div>
//                             </Link>
//                         </div>
//                     )
//                 })}
//             </div>
//         </>
//     )
// }

// export default MarketSold