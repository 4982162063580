import "./place.scss";
import Navbar from "../landing/header/Navbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import CheckHigh from "../../hooks/dataFetchers/getHighestBid";
import CheckExpire from "../../hooks/dataFetchers/checkExpiry";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";
import CopyToClipboard from 'react-copy-to-clipboard'
import "react-toastify/dist/ReactToastify.css";
import useWeb3 from "../../hooks/useWeb3";
import Claim from "../../hooks/dataSenders/claim";
import { useWeb3React } from "@web3-react/core";
import "./place.scss";
import Loader from "../../hooks/loader";
import { Link, useHistory, useParams } from "react-router-dom";
import Countdown from "react-countdown";
import Nftbiding from "../../hooks/dataSenders/bid";
import CancelBid from "../../hooks/dataSenders/cancelBid";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWindowSize } from 'react-use';
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Confetti from 'react-confetti'
export const Place = () => {
  const { width, height } = useWindowSize();
  const { type } = useParams();
  let cardType = type.split(':')[1]
  let typeParamId = type?.split(':')[0]
  console.log('sladflad', typeParamId)
  const { account } = useWeb3React();
  const [loader, setLoader] = useState();
  const [highestBid, setHighestBid] = useState(0);
  // console.log('jghhjhty', highestBid)
  const [bidAmount, setBidAmount] = useState(0);
  const [minimumBid, setMinimumBid] = useState(0.1);
  const [bided, setBided] = useState(false);
  const [userBid, setUserBid] = useState(null);
  const [balance, setBalance] = useState(null);
  const [itemArr, setItemArr] = useState([]);
  const [auction, setAuction] = useState(false);
  const [windowReload, setWindowReload] = useState(false);
  const [errors, setErrors] = useState()
  const [copied, setCopied] = useState(false)
  const history = useHistory()
  const [price, setPrice] = useState(null);
  const [bidNftState, setBidNftState] = useState()
  const [cancelBidState, setCancelBidState] = useState()
  const [claimBidDetail, setClaimBidDetail] = useState()
  const [claimBidDetailTwoState, setClaimBidTwoState] = useState()
  const [bidsMade, setBidsMade] = useState([]);
  const [timerDat, setTimerdat] = useState('');
  const [firstbider, setFirstbider] = useState('')
  const [increaseBid, setIncreaseBid] = useState('');
  const [congrats, setCongrats] = useState(false)
  const [urlLoc, setUrlLoc] = useState()
  const [congratsModal, setCongratsModal] = useState(true)
  const [timeline, setTimeline] = useState('');
  const [error, setError] = useState();
  const [TBid, setTBid] = useState(0);
  const { UserClaim } = Claim();
  const web3 = useWeb3();
  const { HighestBid } = CheckHigh();
  const { CheckExpiry } = CheckExpire();
  const { UserBiding } = Nftbiding();
  const { CancelBiding } = CancelBid();
  const contractAddress = Environment.tomiToken;
  const contract = gettomiTokenContract(contractAddress, web3);
  const socketUrl = 'https://api.tomiback.co';
  var data = JSON.stringify({
    query: `query MyQuery {
      auctionCreateds(where: {tokenId: "${typeParamId}"}) {
        expiryTime
        id
        isClaimed
        label
        labelhash
        mintAmount
        minter
        startTime
        tld
        tokenId
      }
    }`,
    variables: {},
  });
  var config = {
    method: "post",
    url: Environment.marketplaceGraph,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const getBidDetail = async () => {
    setLoader(true)
    await axios(config)
      .then(async function (response) {
        if (response?.data?.data?.auctionCreateds?.length > 0) {
          getBidDetailFun(response)
          highestBidFun()
          expirtyTimeFun()

        } else {
          getBidDetail()
        }
        setLoader(false)
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  const getBidDetailFun = async (response) => {
    setLoader(true)
    let bid = response.data.data.auctionCreateds;
    let dumObj = bid[0];
    setItemArr(dumObj);
    setFirstbider(dumObj?.minter)
    getData(dumObj);
    setLoader(false)
  }
  const highestBidFun = async () => {
    let res0 = await HighestBid(typeParamId);
    localStorage.setItem('tokenId', res0?.bidder)
    await setHighestBid(res0);
    let minBid = parseFloat(res0?.amount) === 0 ? '0' : res0?.amount / 1000000000000000000
    minBid = parseFloat(minBid)
    let p10 = (minBid * 15) / 100;
    minBid = minBid + p10;
    setMinimumBid(minBid)
  }
  const expirtyTimeFun = async () => {

    let res1 = await CheckExpiry(typeParamId);

    let dat = new Date(0);
    dat.setUTCSeconds(parseFloat(res1?.expiryTime));
    let dateVal = null;
    setTimerdat(dat)
    if (dat) {
      dateVal = new Date(parseFloat(res1?.expiryTime) * 1000);
      res1.expiryTime = dateVal;
      const date0 = new Date();

      if (dat > date0) {
        setAuction(true);
      } else {
        setAuction(false);
      }
      await setTimeline(res1);
    }
  }
  // console.log('firsddddt', auction)
  useEffect(() => {
    if (account) {
      setBidAmount(0);
    }
  }, [account, itemArr, windowReload]);


  const getBalance = async () => {
    if (account) {
      let res = await contract.methods
        .balanceOf(account)
        .call({
          from: account,
        })
      await setBalance(parseFloat(web3.utils.fromWei(res, "ether")).toFixed(0));
    }
  };

  const getData = (bid) => {
  };

  const OKay = () => {
    window.$("#accept").modal("hide");
  }

  const closeWithdraw = () => {
    window.$("#withdraw").modal("hide");
  }

  const closeCancel = () => {
    window.$("#cancelmodal").modal("hide");
  }

  const BidNft = async () => {
    if (account) {
      try {
        let prevBid = bidsMade?.find((i) => {
          return i?.bidder?.toUpperCase() === account.toUpperCase();
        });
        if (prevBid) {
          prevBid = parseInt(prevBid?.totalBalance) / 1000000000000000000;
        } else {
          prevBid = 0;
        }
        let val = highestBid > 0 ? parseFloat(highestBid?.amount) / 1000000000000000000 : 0 || minimumBid;
        val = parseFloat(val);
        let p10 = (val * 15) / 100;
        val = val - p10;
        let pre = parseFloat(price);

        let pri = pre + prevBid;
        if (pre > val) {
          try {
            setLoader(true);
            let prevBid = parseFloat(bidAmount) / 1000000000000000000;
            // // console.log('sladflad bid', )
            const res = await UserBiding(
              parseFloat(price),
              typeParamId,
              account,
              prevBid
            );
            var socket = io(socketUrl, {
              path: '/socket',
              autoConnect: false,
            });
            socket.open();
            typeParamId = type?.split(':')[0];
            // console.log('bid method', typeParamId)
            socket.emit("bid", typeParamId);
            setBidNftState(res)
            setLoader(false);
            setError("");
            window.$("#placebid").modal("hide");
            window.$("#accept").modal("show");
            window.$("#increase-bid").modal("hide");
          } catch (err) {
            setError(err.message);
            setLoader(false);
            window.$("#placebid").modal("hide");
            if (err?.message?.includes('User denied transaction')) {
              toast.error('User Denied Trasaction', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            } else {
              toast.error('Sorry, Your offer didn’t go through. Another participant has submitted a higher bid than yours.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            }
          }
        } else {
          setErrors(`Bid amount must be greater than ${minimumBid} Tomi`);
        }
      } catch (err) { }

    } else {
      toast.error('Connect Your Wallet First', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const adjustPrice = (val) => {
    let currentbid = parseFloat(userBid?.amount / 1000000000000000000);
    let b = parseFloat(val)
    setPrice(b); let a = b + 0
    setTBid(a)
    if (b < balance) {
      if (bided === true) {
        if (b > currentbid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors('')
          toast.error(error?.split('::')[1]?.split(",")[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        if (b > minimumBid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors(`Bid amount must be greater than ${minimumBid} Tomi`)
        }
      }

    } else {
    }

  };

  const adjustPrice2 = (val) => {
    let currentbid = parseFloat(userBid?.amount / 1000000000000000000);
    let b = parseFloat(val)
    setIncreaseBid(b)
    let a = b + 0
    setTBid(a)
    if (b < balance) {
      if (bided === true) {
        if (b > currentbid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors(`Bid Amount must be greater than ${(parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) || 0 + (0 * 15 / 100)} Tomi`)
        }
      } else {
        if (b > minimumBid) {
          setPrice(b);
        } else {
          toast.error('Bid amount must be greater Minimum Bid', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }

    } else {

    }

  };

  const Bids = async () => {
    var data = JSON.stringify({
      query: `
    query MyQuery {
      bidCreateds(
        where: {tokenId: "${typeParamId}"}
        orderBy: amount
        orderDirection: desc
      ) {
        amount
        bidder
        id
        tokenId
      }
    }`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(async function (response) {
        // console.log('ggggggg', response?.data?.data?.bidCreateds)
        setBidsMade(response?.data?.data?.bidCreateds);
        localStorage.setItem('highestBid', response?.data?.data?.bidCreateds[0]?.bidder)
        return response?.data?.data?.bidCreateds[0]?.bidder
      })
      .catch(function (error) {
      });
  };
  const CancelBided = async () => {
    if (account) {
      setLoader(true)
      window.$("#cancelmodal").modal("hide");
      try {
        const res = await CancelBiding(
          account,
          userBid?.amount,
          typeParamId
        );
        setCancelBidState(res)
        setLoader(false)
        await toast.success("Bid Cancelled Successfully");
      } catch (error) {
        setLoader(false)
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      setLoader(false)
      await setError("Connect your account");
    }
  };

  const ClaimBid = async () => {
    if (account) {
      window.$("#exampleModal1").modal("hide");
      setLoader(true)
      try {
        const res = await UserClaim(typeParamId, account);
        var socket = io(socketUrl, {
          path: '/socket',
          autoConnect: false,
        });
        socket.open();
        socket.on('connect', function () {
        });
        typeParamId = type?.split(':')[0];
        socket.emit("bid", typeParamId);

        setLoader(false);
        window.$('#acceptOkay').modal('show')
        setClaimBidDetail(res)
        expirtyTimeFun()
        setLoader(false);
      } catch (err) {
        setLoader(false);
        toast.error(err?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0] || err?.message?.split(':')[1], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      }
    } else {
      setError("Connect your wallet");
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      window.location.reload()
      return null
    } else {
      // Render a countdown

      return (
        <span>
          {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
        </span>
      );
    }
  };
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  useEffect(() => {
    if (account) {
      for (let i of bidsMade) {
        if (i.bidder?.toUpperCase() === account?.toUpperCase()) {
          setUserBid(i)
          let weiBid = (parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) || 0 + (0 * 15 / 100)
          setBided(true);
        }
      }
    }

  }, [bidsMade, account, highestBid?.amount, windowReload]);
  useEffect(() => {
    getBidDetail();
    getBalance();
  }, [account, bidNftState, cancelBidState, claimBidDetail, claimBidDetailTwoState, windowReload]);
  //here is the code to take bid update when it delay its response
  useEffect(() => {
    // if (typeParamId) {
    //   setInterval(() => {
    //     if (bidsMade[0]?.bidder) {
    //       let heighestbidEffect = highestBid?.bidder?.toLowerCase();
    //       let heighestbidGraph = bidsMade[0]?.bidder?.toLowerCase();
    //       if (!(heighestbidEffect === heighestbidGraph)) {
    //         Bids();
    //         highestBidFun()
    //       } else {
    //       }
    //     }
    //   }, 4000);
    // highestBidFun()
    // expirtyTimeFun()
    Bids();
    // }
  }, [account, itemArr, bidsMade[0]?.bidder, highestBid]);
  useEffect(() => {
    getSocket();
  }, [])
  const getSocket = async () => {
    try {
      const socket = io(socketUrl, {
        path: '/socket',
        autoConnect: false,
      });
      socket.on('connect', function () {
        // console.log('Connected', 'connected');
      });
      socket.on('disconnect', (reason) => {
        // console.log(`Disconnected: ${reason}`);
      });
      socket.open();
      socket.on("connect_error", (err) => {
        // // console.log(err instanceof Error); // true
        // console.log(err.message); // not authorized
        // // console.log(err.data); // { content: "Please retry later" }
      });
      socket.on("error", (err) => {
        // console.log(err);
      });
      const listener = (...args) => {
        // console.log('socket ka andar', args, typeParamId, 11111)
        if (args[0] === typeParamId) {
          Bids()
          highestBidFun()
          expirtyTimeFun()
          setTimeout(() => {
            Bids()
            highestBidFun()
            expirtyTimeFun()
          }, 7000);
        }
      }
      socket.on("bidReceived", listener);
    } catch (ex) { }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (highestBid?.bidder && timeline?.isClaimed === false && congratsModal && !auction) {
      if (
        highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
        (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase())
      ) {
        window.$('#acceptOkayClaimed').modal('show')
        setCongrats(true)
      }
    }
  }, [highestBid, account, timeline, itemArr])
  const disconnect = () => {
    var socket = io(socketUrl, {
      path: '/socket',
      autoConnect: false,
    });
    socket.disconnect();
  }
  const shareUrl = `https://tdns.network/place/${typeParamId}`; // The URL you want to share
  const title = `${itemArr?.label}.${itemArr?.tld}`
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      {congrats &&
        <Confetti
          width={width - 20}
          height={height + 100}
        />
      }
      <div>

      </div>
      <section className="main-place">
      <img src='\assets\newshade.svg' className='myyyelipseww img-fluid'/>
      <img src='\assets\newshade.svg' className='myyyelipse2dd img-fluid'/>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-12 m-auto p-0">
              <div className="botton">
                <button onClick={() => { cardType === 'mintedDomain' ? history.push('/domainSearhResult/active/mm') : history.goBack() }} className="btn-back" >
                  <img
                    src="\assets\arrow-left.svg"
                    className="img-fluid "
                    alt="img"
                  />
                  Back
                </button>
              </div>
              <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12 pl-0">
                  {cardType === 'minted' ?
                    <div className="maincard mainCardtwo">
                      <h3 className="cardinnerheading cardinnerheadingRelative">{itemArr?.label}.{itemArr?.tld}</h3>
                      <img src="\mintCard\tomi.svg" alt="" className="img-fluid  tomis" />
                      <img src="\mintCard\goverMint.svg" alt="" className="img-fluid govern" />
                    </div>
                    :
                    <div className="maincard" >
                      <h3 className="cardinnerheading ">{itemArr?.label}.{itemArr?.tld}</h3>
                    </div>
                  }
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12">
                  <div className="bittingpills">
                    <div className="heading-bid">
                      <h5 className="detailBidH">{itemArr?.label?.substring(0, 12)}{itemArr?.label?.length > 12 && '(...)'}.{itemArr?.tld}</h5>
                      {(itemArr?.minter?.toLowerCase() === account?.toLowerCase()) && <button className="btn-sharee" data-toggle="modal" data-target="#exampleModal"><img src="\assets\share-icon.svg" alt="img" className="img-fluid" />Share</button>}
                    </div>
                    <div className="bid-tabs">
                      <nav className="justify-content-center">
                        <div className="nav nav-tabs border-0 justify-content-center" id="nav-tab">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#nav-Bids"
                          >
                            Bids History
                          </a>
                          <a
                            className="nav-link pl-0"
                            data-toggle="tab"
                            href="#nav-Information"
                          >
                            Information
                          </a>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-Bids">
                          <div className={highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                              (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase() )? "bidhistory" : "bidhistory myheight" }>
                            {bidsMade?.map((item) => {
                              return (
                                <>
                                  <div className="content-history">
                                    <div className="fitcont">
                                      <ul className="list-inline maaiinnoonnee">
                                        <li className="list-inline-item">
                                          <div className="history-heads">
                                            <p className="hours">Bid by</p>
                                            <h3 className="address-title">
                                              {item?.bidder}{" "}
                                            </h3>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <ul className="list-inline">
                                      <li className="list-inline-item shoqatali">
                                        <h4 className="shoqii">{(parseFloat(item?.amount) /
                                          1000000000000000000)?.toString()?.length > 4 ? (parseFloat(item?.amount) /
                                            1000000000000000000)?.toFixed(2) : parseFloat(item?.amount) /
                                        1000000000000000000}{" "} TOMI</h4>
                                        {/* <p className="sectime">= $12.246</p> */}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="brdr"></div>
                                </>
                              )

                            })}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade "
                          id="nav-Information"
                        >
                          <div className="information">
                            <div className={highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                              (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase() )? "bid-card" : "bid-card myheight" }>
                              <div className="inner-content">
                                <h3 className="">Contract Address</h3>
                                <p className="">{Environment.MarketContract}</p>
                                <h3 className="">Token ID</h3>
                                <h5 className="text-truncate">{typeParamId} </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lastcardbtn position-sticky">
                      <div className="main-cardsbid">
                        <div className="history-cards2">
                          <div className="history-content auction-history">
                            {auction === true ? (<>
                              <h5 className="">Auction Ends in</h5>
                              <p className="">
                                <>
                                  {timeline?.expiryTime && (
                                    <Countdown
                                      date={timerDat}
                                      renderer={renderer}
                                    ></Countdown>
                                  )}
                                </>
                              </p>
                            </>) : <h5 className="">Auction Ended</h5>}
                          </div>
                          <div className="history-content auction-history">
                            <h5 className="">Highest Bid</h5>
                            <div className="history-inner">
                              <p className="text-lowercase">{((parseFloat(highestBid?.amount) / 1000000000000000000)?.toString()?.length > 6 ? (parseFloat(highestBid?.amount) / 1000000000000000000)?.toFixed(2) : (parseFloat(highestBid?.amount) / 1000000000000000000)) || 0}{" "} tomi</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 p-0">
                          {/* place a bid button show on place a bid component */}
                          {/* jawad auction */}
                          {auction === true ? (
                            <>
                              {/* jawad bided */}
                              {bided === true ? <button
                                className="btnbtnbid"
                                data-toggle="modal"
                                data-target="#increase-bid"
                              >
                                Increase Bid
                              </button> : <button
                                className="btnbtnbid"
                                data-toggle="modal"
                                data-target="#placebid"
                              >
                                Place a Bid
                              </button>}
                            </>

                          ) : ''}



                        </div>
                      </div>
                      <div className="withdraw-increase ">
                        {auction === true ? (
                          <>
                            {bided ?
                              auction === true || <button
                                className="cancelmodal"
                                data-toggle="modal"
                                data-target="#withdraw"
                              >
                                Cancel Bid
                              </button>

                              :
                              ''}
                          </>
                        ) :
                          <>
                            {highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                              (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase())
                              ?
                              //  <p className="claimdomainpara">Congrats! You can claim your Domain in a few hours</p>
                              <button
                                className="withdraw"
                                onClick={ClaimBid}
                              >
                                Claim
                              </button>
                              :
                              <>
                                {highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === true ?
                                  null
                                  :
                                  <>
                                    {bided === true && highestBid?.bidder?.toUpperCase() != account?.toUpperCase() && userBid?.totalBalance > 0 ?

                                      null
                                      : null}
                                  </>}
                              </>
                            }
                          </>
                        }
                      </div>

                      {/* {!account &&
                        <button className="button-hedaer w-100 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button>

                      } */}
                      <div className="mydomain-btns w-100 d-none">
                        <Link className="w-100">
                          <button className="putonmarket">
                            Put On Marketplace
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid-modal">
        <div
          className="modal fade"
          id="placebid"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Place a Bid
                </h3>

                
              </div>
              <div className="modal-body">
                <p className="">
                  You are about to Place a Bid on<span className="px-2">{itemArr?.label}.{itemArr?.tld}</span>
                </p>
                <div className="input-field">
                  <div className="upper-text">
                    <p>Your Bid</p>
                    <p>
                      Bid must be greater than: <span> {minimumBid?.toString()?.length > 6 ? minimumBid?.toFixed(2) : minimumBid} TOMI </span>
                    </p>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Enter Bid"
                    step={0.1}
                    value={price}
                    min={0.0}
                    onChange={(e) => adjustPrice(e.target.value)}
                    type={"number"}
                  />
                  <p className="text-danger fSm">{errors}</p>
                  <span className="input-inner">TOMI</span>
                </div>
                <div className="text-modalbid mt-4">
                  <h4 className="bidhead">Your Balance</h4>
                  <h3 className="head1 ml-2">{balance || 0} TOMI</h3>
                </div>
                <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Total Bid amount</h4>
                  <h3 className="head1 ml-2"> {(TBid || 0)?.toFixed(2)} TOMI</h3>
                </div>
                {error != null && price < minimumBid ?
                  <h3 className="text-center w-100 text-danger pt-2">
                    {error?.split('::')[1]?.split(",")[0]}
                  </h3> :
                  (
                    <>{price > balance ? (
                      account ?
                        <h5 className="text-center w-100 text-danger  pt-2">
                          Insufficient Token Balance
                        </h5>
                        :
                        <button onClick={() => window.$('#placebid').modal('hide')} data-toggle="modal"
                          data-target="#exampleModalwallet"
                          type="button" className="btnmodalbtn">Connect Wallet</button>

                    ) :
                      (
                        <>{price > minimumBid ?
                          (<button
                            className="btnmodalbtn" onClick={BidNft}
                          >
                            Place a Bid
                          </button>) : ''}</>
                      )
                    }
                    </>

                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="accept"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Place a Bid
                </h3>
                
              </div>
              <div class="modal-body">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p className="set-custom-weight">
                  Your bid has been <br /> placed successfully
                </p>
                <p className='alertWar'>New bid data will take some time to display</p>
                <button onClick={OKay}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="withdraw"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Withdraw Bid
                </h3>
                
              </div>
              <div class="modal-body">
                <div className="withdraw-body">
                  <p className="withdraw-para">
                    Are you Sure to <br /> Withdraw your Bid
                  </p>
                  <div className="withdraw-btn">
                    <button className="cancel" onClick={closeWithdraw}>Cancel</button>
                    <button className="confirm" onClick={CancelBided} >Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="cancelmodal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Cancel Bid
                </h3>
                
              </div>
              <div class="modal-body">
                <div className="withdraw-body">
                  <p className="withdraw-para">
                    Are you Sure to <br /> Cancel your Bid
                  </p>
                  <div className="withdraw-btn">
                    <button className="cancel" onClick={closeCancel}>Cancel</button>
                    <button className="confirm" onClick={CancelBided} >Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid-modal">
        <div
          className="modal fade"
          id="increase-bid"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Increase Bid
                </h3>

                
              </div>
              <div className="modal-body">
                <div className="input-field">
                  <div className="upper-text">
                    <p>You Bid</p>
                    <p>
                      Bid must be greater than: <span>{((parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100))?.toFixed(3) || 0 + (0 * 15 / 100)}
                        {/* {(userBid?.amount / 1000000000000000000)+((userBid?.amount / 1000000000000000000)*10/100)}  */}
                        {' '} TOMI </span>
                    </p>
                  </div>
                  <input type="number" step={0.1} placeholder="Enter Bid" onChange={(e) => adjustPrice2(e.target.value)} />

                  {/* <input type="number" step={0.1} placeholder="Enter Bid" value={increaseBid} onChange={(e) => adjustPrice2(e.target.value)} /> */}
                  <p className="text-danger fSm">{errors}</p>
                  <span className="input-inner">TOMI</span>
                </div>
                <div className="text-modalbid mt-5">
                  <h4 className="bidhead">Your Balance</h4>
                  <h3 className="head1 ml-2">{balance} TOMI</h3>
                </div>
                {/* <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Service fee</h4>
                  <h3 className="head1 ml-2">0.00 TOMI</h3>
                </div> */}
                <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Total Bid amount</h4>
                  <h3 className="head1 ml-2">{TBid > 0 ? parseFloat(TBid).toFixed(2) : 0} TOMI</h3>
                </div>
                <p className="text-center w-100 text-danger pt-4">
                  {error?.split('::')[1]?.split(",")[0]}
                </p>
                {/* userBid?.amount / 1000000000000000000 */}
                {/* // (i?.amount / 1000000000000000000)   (parseFloat(highestBid?.amount) / 1000000000000000000)+((parseFloat(highestBid?.amount) / 1000000000000000000)*10/100) */}
                {increaseBid > (parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) && balance > increaseBid ?
                  <button className="btnmodalbtn" onClick={BidNft}>Increase Bid</button>
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="setting-modal">
        <div
          class="modal fade"
          id="setting"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h6>MintING</h6>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="\close-icon.png" alt="img" className="img-fluid" />
                </button>
              </div>
              <div class="modal-body">
                <p className="upper-para">No. of years</p>
                <div className="upper">
                  <div className="left">
                    <button>
                      <img src="\minus.png" alt="img" className="img-fluid" />
                    </button>
                  </div>
                  <div className="mid">1</div>
                  <div className="right">
                    <button>
                      <img src="\plus.png" alt="img" className="img-fluid" />
                    </button>
                  </div>
                </div>
                <div className="mid">
                  <div className="text">
                    <p className="para1">Previous Subscription Period:</p>
                    <p className="para2">1 Year</p>
                  </div>
                  <div className="text">
                    <p className="para1">Subscription Fee</p>
                    <p className="para2">0.01 TOMI</p>
                  </div>
                </div>
                <div className="bottom">
                  <p className="para1">Total Amount</p>
                  <p className="para2">1.123 TOMI</p>
                </div>
                <button className="mint">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          data-backdrop="static" data-keyboard="false"
          class="modal fade"
          id="acceptOkay"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Claimed Successfully
                </h3>
                {/*  */}
              </div>
              <div class="modal-body modal-body2">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  Your Domain has been <br /> claimed successfully
                </p>
                <button onClick={() => window.$('#acceptOkay').modal('hide')}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          data-backdrop="static" data-keyboard="false"
          class="modal fade"
          id="acceptOkayClaimed"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Congratulations
                </h3>
              </div>
              <div class="modal-body modal-body2">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  You won the auction !<br /> Please claim within 15 days
                </p>
                <button onClick={() => { window.$('#acceptOkayClaimed').modal('hide'); setCongratsModal(false); setCongrats(false) }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* share modal ..................... */}
      <div className="share-modal-parent">
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Share</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img src="\assets\close-icon-new.svg" alt="img" className="img-fluid" /></span>
                </button>
              </div>
              <div class="modal-body">
                <div className="parent-img-wrapper">
                  <img src="\assets\sharetdns-modalimg.svg" alt="img" className="img-fluid" />
                  <h6>{itemArr?.label?.substring(0, 12)}{itemArr?.label?.length > 12 && '(...)'}.{itemArr?.tld}</h6>
                </div>
                <div className="bottom-links">
                  <h6>Share with friends</h6>
                  <div className="inner-links">
                    {/* {`Check out my domain ${itemArr?.label}.${itemArr?.tld} on tdns.network`} */}
                    <WhatsappShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img data-toggle="tooltip" data-placement="top" title={''} src="\assets\whatsapp-icon.svg" alt="img" className="img-fluid" />
                    </WhatsappShareButton>
                    <TwitterShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img src="\assets\twitter-new-icon.svg" alt="img" className="img-fluid" />
                    </TwitterShareButton>
                    <TelegramShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img src="\assets\telegram-new-icon.svg" alt="img" className="img-fluid" />
                    </TelegramShareButton>

                    {/* <a href="#"><img src="\assets\insta.svg" alt="img" className="img-fluid" /></a> */}
                  </div>
                </div>
                <div className="last-part">
                  <h6 className="text-truncate"><img src="\assets\link-icon.svg" alt="img" className="img-fluid" />https://tdns.network/place/{typeParamId?.slice(0, 8)}...</h6>
                  <a href="#">
                    {!copied ?
                      <CopyToClipboard text={`https://tdns.network/place/${typeParamId}`}
                        onCopy={() => { setCopied('Copied'); settime() }}>
                        <img src="\assets\copy-address.svg" alt="img" className='img-fluid imoo' style={{ cursor: "pointer" }} /></CopyToClipboard> :
                      <p className=" copy text-common">Copied</p>}
                  </a>
                  {/* <img src="\assets\copy-address.svg" alt="img" className="img-fluid" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Place;