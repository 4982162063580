import React, { useEffect, useState } from "react";
import Navbar from "../landing/header/Navbar";
import "./domainprofile.scss";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import DomainProfile from '../../hooks/dataSenders/domainProfile'
import { toast } from "react-toastify";
import Loader from '../../hooks/loader'
import Crypto from "./Crypto";
import Website from "./Website";
import Transfer from "./Transfer";
import SellDomain from "./SellDomain";
import Email from "./Email";
import { IpfsStorage } from "../../hooks/ipfs";
import { useWeb3React } from "@web3-react/core";
import SubDomain from "./SubDomain";
import { urlSource } from "ipfs-http-client";
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom';
const DomainProfileCont = () => {
  const [userProfile, setUserProfile] = useState()
  const [coverPhoto, setCoverPhoto] = useState()
  const [showSide, setShowSide] = useState();
  const [profilesData, setProfilesData] = useState('')
  const history = useHistory()
  const [first, setFirst] = useState(false)
  const location = useLocation();
  const { data } = location.state;
  // const { obj } = useParams()
  const UrlData = data;
  // console.log('transferrrr', UrlData)
  const [copied, setCopied] = useState(false)
  const { domainProfileFun } = DomainProfile()
  const [loader, setLoader] = useState(false);
  const { account } = useWeb3React()
  let invalid = false;
  const [profileData, setProfileData] = useState({
    name: '',
    bio: '',
    location: '',
    website: '',
    twitter: '',
    discord: '',
    youtube: '',
    reddit: '',
    telegram: '',
    email: '',
    profileImg: '/assets/userP.svg',
    coverImg: ''
  })
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  const cancle = () => {
    setProfileData({
      name: '',
      bio: '',
      location: '',
      website: '',
      twitter: '',
      discord: '',
      youtube: '',
      reddit: '',
      telegram: '',
      email: '',
      profileImg: '',
      coverImg: ''
    })
  }
  let emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const profileDataFun = async (e) => {

    if (e.target.name === 'coverImg') {
      var file = URL.createObjectURL(e.target.files[0]);
      setCoverPhoto(file);
    } else if (e.target.name === 'profileImg') {
      var file = URL.createObjectURL(e.target.files[0]);
      setUserProfile(file);
    }
    if (e.target.name === 'coverImg' || e.target.name === 'profileImg') {
      let { name, files } = e.target;
      setLoader(true)
      const fileo = await IpfsStorage(files[0])
      setLoader(false)
      setProfileData((pre) => {
        return {
          ...pre, [name]: fileo
        }
      })

    } else {
      let manValue;
      var { name, value } = e.target;
      if (name === 'name' || name === 'bio') {
        manValue = value
      } else {
        manValue = value.replace(/\s/g, "")
      }
      setProfileData((pre) => {
        return {
          ...pre, [name]: manValue
        }
      })
    }
  }
  const confirChanges = async (e) => {
    if (profileData.name || profileData.bio || profileData.location ||
      profileData.location ||
      profileData.website ||
      profileData.twitter ||
      profileData.discord ||
      profileData.youtube ||
      profileData.reddit ||
      profileData.telegram ||
      profileData.email ||
      profileData.profileImg ||
      profileData.coverImg) {

      if (!UrlData.name) {
        toast.error('domain name missing', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else if (false) {
        toast.error('TokenId is missing')
      }
      else if (true) {
        let jsonData = JSON.stringify(profileData)
        try {
          setLoader(true)
          const res = await domainProfileFun(UrlData.name, 'profile', jsonData, false)
          toast.success('Domain Profile Set Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          toast.info('Profile Pictures will take sometime to show!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          setLoader(false)
        } catch (error) {
          toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          setLoader(false)
        }

      } else {
      }
    } else {
      toast.error('Fill fields first!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }

  }

  const getProfileData = async (e) => {

    if (UrlData.name) {
      try {
        setLoader(true)
        const res = await domainProfileFun(UrlData.name, 'profile', '00', true)
        setProfilesData(JSON.parse(res))
        setLoader(false)
        if (res) {
          const resObj = JSON.parse(res)
          setProfileData({
            name: resObj?.name,
            bio: resObj?.bio,
            location: resObj?.location,
            website: resObj?.website,
            twitter: resObj?.twitter,
            discord: resObj?.discord,
            youtube: resObj?.youtube,
            reddit: resObj?.reddit,
            telegram: resObj?.telegram,
            email: resObj?.email,
            profileImg: resObj?.profileImg,
            coverImg: resObj?.coverImg
          })
        }
      } catch (error) {
        setLoader(false)
      }

    } else {
    }

  }
  useEffect(() => {
    getProfileData()
    // // console.log('jdkjfieorldfjef',first)
  }, [UrlData?.name, account, first])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const sidebar = () => {
    // // console.log("adfdssdf");
    if (showSide === true) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  };

  return (
    <>
    <div className="myyyyyydv">
    <img src='\assets\newshade.svg' className='myyyelipse img-fluid'/>
    <img src='\assets\BG.svg' className='myyyelipse2 img-fluid'/>
    {loader && <Loader />}
      <Navbar />
      
      <section className="domainprofile">
    
        <div className="custom-container">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <a onClick={sidebar} className="sidebar-icon">
                <img
                  src="\assets\sidebar-left.svg"
                  alt="img"
                  className="img-fluid"
                />
              </a>
              <Link onClick={() => history.goBack()}>
                <a className="back-btn d-flex align-items-center">
                  <img
                    src="\assets\arrow-back.svg"
                    alt="img"
                    className="img-fluid mr-2"
                  />
                  Back
                </a>
              </Link>
              <div className="top-heading">
                <div className="left">
                  <img
                    src="\searchResults\polyInfinite.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="right">
                  <h4 className="forellipsisset">{UrlData.name && UrlData.name}</h4>
                  <span style={{position : 'relative' , maxWidth : 'fit-content'}}>
                    <p className="break-word-sm" >
                    Domain owner address:</p> <span className=" text-truncate ml-2"> {account?.slice(0,5)}...{account?.slice(-4)}</span>{" "}
                    {!copied ?
                      <CopyToClipboard text={account}
                        onCopy={() => { setCopied('Copied'); settime() }}>
                        <img
                          src="\assets\copy.svg"
                          alt="img"
                          className="img-fluid ml-2 cPointer copiedparatextimg"
                        />
                      </CopyToClipboard>
                      : <p className="ml-2 common copiedparatext">Copied</p>}
                  </span>
                </div>
              </div>

              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col
                    lg={3}
                    className={
                      showSide === true
                        ? "mobielSidebarShow"
                        : showSide === false
                          ? "mobielSidebarHide "
                          : "mobile-side-none"
                    }
                  >
                    <img
                      src="\assets\close-btn.svg"
                      alt="img"
                      className="img-fluid close-btn d-none"
                      onClick={sidebar}
                    />
                    <Nav variant="pills" className="flex-column sidebar">
                      <h5 className="heading">Manage</h5>
                      <Nav.Item>
                        <Nav.Link eventKey="first" onClick={sidebar}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
                              fill="#FFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.25 9C2.25 8.58579 2.58579 8.25 3 8.25H21C21.4142 8.25 21.75 8.58579 21.75 9C21.75 9.41421 21.4142 9.75 21 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9Z"
                              fill="#FFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.25 15C2.25 14.5858 2.58579 14.25 3 14.25H21C21.4142 14.25 21.75 14.5858 21.75 15C21.75 15.4142 21.4142 15.75 21 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15Z"
                              fill="#FFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.6 2.66837C11.2749 1.68053 12.7253 1.68054 13.4001 2.6684C15.5999 5.88837 16.7499 8.91792 16.7499 12C16.7499 15.0821 15.5999 18.1116 13.4001 21.3316C12.7253 22.3195 11.2749 22.3195 10.6 21.3317C8.40014 18.1117 7.24999 15.0821 7.25 12C7.25001 8.9179 8.40018 5.88834 10.6 2.66837ZM12.1616 3.51453C12.0823 3.39849 11.9179 3.39848 11.8386 3.51453C9.74606 6.57741 8.75001 9.31498 8.75 12C8.74999 14.685 9.74602 17.4226 11.8386 20.4855C11.9178 20.6015 12.0823 20.6015 12.1616 20.4855C14.254 17.4226 15.2499 14.685 15.2499 12C15.2499 9.31497 14.254 6.5774 12.1616 3.51453Z"
                              fill="#FFF"
                            />
                          </svg>
                          Website
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="second" onClick={sidebar}>
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <path d="M4.94203 13.5049C2.62319 12.534 1 10.2039 1 7.52427C1 3.91262 3.89855 1 7.49275 1C10.0821 1 12.3237 2.51456 13.3672 4.72816M12.0145 12.4175C12.0145 12.2621 12.0145 12.1068 12.0145 11.9515C12.0145 8.33981 14.913 5.42718 18.5072 5.42718C22.1014 5.42718 25 8.33981 25 11.9515C25 15.5631 22.1014 18.4757 18.5072 18.4757M12.0918 20.767L9.38647 22.9417L6.64251 20.767M11.3961 8.2233H9.85024V5.03883L7.64734 7.52427L5.48309 5.03883V8.2233H3.9372M17.4638 14.5922H19.3961C20.1304 14.5922 20.7488 13.9709 20.7488 13.233C20.7488 12.4563 20.1304 11.8738 19.3961 11.8738H17.4638V14.5922ZM17.4638 14.5922H16.8454M17.4638 14.5922V15.4466M16.8454 9.38835H17.4638V8.53398M18.8164 8.53398V9.38835M18.8164 14.5922V15.4466M15.8406 18.4757C15.8406 22.0874 12.942 25 9.34783 25C5.75362 25 2.89372 22.0874 2.89372 18.4757C2.89372 14.8641 5.79227 11.9515 9.38647 11.9515C12.9807 11.9515 15.8406 14.9029 15.8406 18.4757ZM12.0918 18.2039L9.38647 20.6116L6.64251 18.2039L9.38647 14.0097L12.0918 18.2039ZM20.4783 10.6311C20.4783 11.3301 19.9372 11.8738 19.2415 11.8738H17.5024V9.38835H19.2415C19.8986 9.38835 20.4783 9.93204 20.4783 10.6311Z" stroke="#FFF" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />

                          </svg>
                          Crypto
                        </Nav.Link>
                      </Nav.Item> */}
                      {/* <Nav.Item>
                        <Nav.Link eventKey={'third'} onClick={sidebar}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <path
                              d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                              stroke="#FFF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
                              stroke="#FFF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Profile
                        </Nav.Link>
                      </Nav.Item> */}

                     {((UrlData?.name?.split('.').length - 1) === 1) && <Nav.Item>
                        <Nav.Link eventKey="forth" onClick={sidebar}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <path
                              d="M20.5 22H3.5"
                              stroke="#FFF"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 3.5L5 17.5"
                              stroke="#FFF"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 13.77V3.5H8.73"
                              stroke="#FFF"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Transfer
                        </Nav.Link>
                      </Nav.Item>}
                      {((UrlData?.name?.split('.').length - 1) === 1) && <Nav.Item>
                        <Nav.Link eventKey="fifth" onClick={sidebar}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <g clip-path="url(#clip0_46_2854)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M21.8221 12.3989C22.5056 13.0823 22.5056 14.1903 21.8221 14.8737L14.8727 21.8232C14.1893 22.5066 13.0813 22.5066 12.3978 21.8231L2.94329 12.3686C2.6151 12.0404 2.43073 11.5953 2.43073 11.1312L2.43073 4.18175C2.43073 3.21525 3.21423 2.43175 4.18073 2.43175L11.1302 2.43175C11.5943 2.43175 12.0394 2.61612 12.3676 2.94431L21.8221 12.3989ZM20.7615 13.8131C20.8591 13.7154 20.8591 13.5571 20.7615 13.4595L11.3069 4.00497C11.26 3.95809 11.1965 3.93175 11.1302 3.93175L4.18073 3.93175C4.04266 3.93175 3.93073 4.04368 3.93073 4.18175L3.93073 11.1312C3.93073 11.1975 3.95707 11.2611 4.00395 11.3079L13.4585 20.7625C13.5561 20.8601 13.7144 20.8601 13.812 20.7625L20.7615 13.8131Z"
                                fill="#FFF"
                              />
                              <path
                                d="M7.36266 9.45555C6.78527 8.87816 6.78527 7.94203 7.36266 7.36464C7.94005 6.78725 8.87618 6.78725 9.45357 7.36464C10.031 7.94203 10.031 8.87816 9.45357 9.45555C8.87618 10.0329 7.94005 10.0329 7.36266 9.45555Z"
                                fill="#FFF"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_46_2854">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Sell Domain
                        </Nav.Link>
                      </Nav.Item>}
                      <Nav.Item>
                        <Nav.Link eventKey="eight" onClick={sidebar}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="change-my-color"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4 5.75C3.30964 5.75 2.75 6.30964 2.75 7V18C2.75 18.6904 3.30964 19.25 4 19.25H20C20.6904 19.25 21.25 18.6904 21.25 18V7C21.25 6.30964 20.6904 5.75 20 5.75H4ZM1.25 7C1.25 5.48122 2.48122 4.25 4 4.25H20C21.5188 4.25 22.75 5.48122 22.75 7V18C22.75 19.5188 21.5188 20.75 20 20.75H4C2.48122 20.75 1.25 19.5188 1.25 18V7Z"
                              fill="#FFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.827 8.2243C2.00963 7.85252 2.45907 7.69918 2.83085 7.88181L11.8899 12.3319C11.9595 12.366 12.0409 12.366 12.1104 12.3319L21.1695 7.88181C21.5413 7.69918 21.9907 7.85252 22.1733 8.2243C22.356 8.59608 22.2026 9.04552 21.8308 9.22814L12.7717 13.6782C12.2851 13.9173 11.7152 13.9173 11.2286 13.6782L2.16949 9.22814C1.79771 9.04552 1.64437 8.59608 1.827 8.2243Z"
                              fill="#FFF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.6179 12.6272C15.543 12.5728 15.4442 12.5642 15.3611 12.605L12.7701 13.8746C12.2843 14.1126 11.7158 14.1126 11.23 13.8746L8.63905 12.605C8.55593 12.5642 8.4571 12.5728 8.38218 12.6272L2.44065 16.9403C2.10544 17.1836 1.63645 17.1091 1.39311 16.7739C1.14978 16.4387 1.22426 15.9697 1.55946 15.7264L7.501 11.4133C8.02538 11.0326 8.71719 10.9729 9.29908 11.258L11.89 12.5276C11.9594 12.5616 12.0407 12.5616 12.1101 12.5276L14.701 11.258C15.2829 10.9729 15.9747 11.0326 16.4991 11.4133L22.4406 15.7264C22.7759 15.9697 22.8503 16.4387 22.607 16.7739C22.3637 17.1091 21.8947 17.1836 21.5595 16.9403L15.6179 12.6272Z"
                              fill="#FFF"
                            />
                          </svg>
                          Subdomain
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={9}>
                    <Tab.Content className="content-side">
                      <Tab.Pane eventKey="first">
                        <Website UrlData={UrlData.name} setLoader={setLoader} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Crypto UrlData={UrlData.name} setLoader={setLoader} />
                      </Tab.Pane>

                      <Tab.Pane eventKey={'third'}>
                        <section className="first">
                          <div className="cover" style={{ backgroundImage: `url(${coverPhoto ? coverPhoto : profileData?.coverImg})` }}>
                            {/* <img src="" alt="img" className="img-fluid w-100" /> */}
                            <label htmlFor="coverPhoto"> <a className="add-cover ">
                              <img
                                src="\assets\profile\cover-icon.svg"
                                alt="img"
                                className="img-fluid mr-2"
                              />{" "}
                              Add cover
                            </a></label>
                            <input onChange={profileDataFun} className="d-none" type="file" name="coverImg" id="coverPhoto" />
                            {/* <label htmlFor="uploadProfile"> <a className="add-avatar">
                              <img
                                src={"/assets/profile/add-avatar.svg"}
                                alt="img"
                                className="img-fluid mr-2"
                              />{" "}
                              Add avatar
                            </a></label> */}
                          </div>
                          <input onChange={profileDataFun} className="d-none" type="file" name="profileImg" id="uploadProfile" />
                          <div className="addAva">
                            <div className="profile">
                              <img
                                src={userProfile ? userProfile : profileData?.profileImg}
                                alt="img"
                                className="profile-img img-fluid"
                              />

                            </div>
                            <label htmlFor="uploadProfile"> 
                            <img
                              src={"/assets/profile/add-avatar.svg"}
                              alt="img"
                              className="img-fluid mr-2 addProfile"
                            />
                            </label>
                          </div>
                          <div className="bottom-content">
                            <div className="user-form">
                              <div className="option-field">
                                <label>Name</label>
                                <input onChange={profileDataFun} value={profileData?.name}
                                  type="text"
                                  placeholder="Add your name"
                                  name="name"
                                />
                              </div>
                              <div className="option-field">
                                <label>short bio</label>
                                <textarea onChange={profileDataFun} value={profileData?.bio} name="bio" placeholder="Add short bio"></textarea>
                              </div>
                              <div className="option-field">
                                <label>Location</label>
                                <input onChange={profileDataFun} value={profileData?.location} name="location" type="text" placeholder="Add Location" />
                                {/* {profileData.location && ((/(http(s?)):\/\//i.test(profileData?.location)) || (invalid = true && <p className="text-danger font-weight-bold">Invalid Location</p>))} */}
                              </div>
                              <div className="option-field">
                                <label>Website</label>
                                <input onChange={profileDataFun} value={profileData?.website} name="website" type="text" placeholder="http://" />
                                {profileData.website && ((/(http(s?)):\/\//i.test(profileData?.website)) || (invalid = true && <p className="text-danger font-weight-bold">Invalid Link</p>))}
                              </div>
                            </div>
                            <div className="socials">
                              <h6 className="socialheading">Socials</h6>
                              <div className="option-field">
                                <label>
                                  <img
                                    src="\assets\profile\Twitter.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  Twitter
                                </label>
                                <input onChange={profileDataFun} value={profileData?.twitter}
                                  type="text"
                                  placeholder="@username or URL"
                                  name="twitter"
                                />
                                {/* {profileData.twitter && ((/(http(s?)):\/\//i.test(profileData?.twitter)) && (profileData.twitter.includes('twitter')) || (invalid = true && <p className="text-danger font-weight-bold">Invalid Twitter Link</p>))} */}
                              </div>
                              <div className="option-field">
                                <label>
                                  <img
                                    src="\assets\profile\Discord.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  Discord
                                </label>
                                <input onChange={profileDataFun} value={profileData?.discord}
                                  type="text"
                                  placeholder="username#code"
                                  name="discord"
                                />
                                {/* {profileData.discord && ((/(http(s?)):\/\//i.test(profileData?.discord)) && (profileData.discord.includes('discord')) || (invalid = true && <p className="text-danger font-weight-bold">Invalid discord Link</p>))} */}
                              </div>
                              <div className="option-field">
                                <label>
                                  <img
                                    src="\assets\profile\YouTube.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  YouTube
                                </label>
                                <input onChange={profileDataFun} value={profileData?.youtube}
                                  type="text"
                                  placeholder="YouTube channel URL"
                                  name="youtube"
                                />
                                {profileData.youtube && ((/(http(s?)):\/\//i.test(profileData?.youtube)) && (profileData.youtube.includes('youtube')) || (invalid = true && <p className="text-danger font-weight-bold">Invalid youtube Link</p>))}
                              </div>
                              <div className="option-field">
                                <label>
                                  <img
                                    src="\assets\profile\Reddit.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  Reddit
                                </label>
                                <input onChange={profileDataFun} value={profileData?.reddit}
                                  type="text"
                                  placeholder="URL"
                                  name="reddit"
                                />
                                {profileData.reddit && ((/(http(s?)):\/\//i.test(profileData?.reddit)) && (profileData.reddit.includes('reddit')) || (invalid = true && <p className="text-danger font-weight-bold">Invalid reddit Link</p>))}
                              </div>
                              <div className="option-field">
                                <label>
                                  <img
                                    src="\assets\profile\Telegram.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  Telegram
                                </label>
                                <input onChange={profileDataFun} value={profileData?.telegram} name="telegram" type="text" placeholder="username" />
                                {/* {profileData.telegram && ((/(http(s?)):\/\//i.test(profileData?.telegram)) && (profileData.telegram.includes('telegram')) || (invalid = true && <p className="text-danger font-weight-bold">Invalid telegram Link</p>))} */}
                              </div>
                              <div className="option-field ">
                                <label> Email</label>
                                <input onChange={profileDataFun} value={profileData?.email} name="email" type="text" placeholder="Add email" />
                                {profileData.email && ((profileData?.email
                                  .toLowerCase()
                                  .match(emailValidator) && true) || (invalid = true && <p className="text-danger font-weight-bold">Invalid Email</p>))}
                              </div>
                            </div>

                            <div className="bottom-btn">
                              <button className={invalid && "bg-secondary cursorNA"} disabled={invalid} onClick={confirChanges}>Confirm Changes</button>
                              <button onClick={cancle}>Cancle</button>
                            </div>
                          </div>
                        </section>
                      </Tab.Pane>
                      <Tab.Pane eventKey="forth">
                        <Transfer UrlData={UrlData.name} setLoader={setLoader} tokenId={UrlData?.id} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <SellDomain />
                      </Tab.Pane>
                      <Tab.Pane eventKey="eight">
                        <SubDomain UrlData={UrlData.name} setLoader={setLoader} tokenId={UrlData.id} subDomains={UrlData?.subdomains} setFirst={setFirst} first={first} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="seven">
                        <Email />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </div>
     

    </>
  );
};

export default DomainProfileCont;
