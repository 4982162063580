import React, { useEffect, useState } from 'react'
import "./refferal.scss"
import HeaderNew from '../LandingNew/HeaderNew/HeaderNew'
import { toast } from 'react-toastify'
import Loader from '../../hooks/loader'
import { API_URL } from '../../ApiUrl'
import axios from 'axios'
import Navbar from '../landing/header/Navbar'


const Settings = () => {
    const [nickName, setNickName] = useState()
    const [loader, setLoader] = useState(false);
    const user = localStorage.getItem('userName') || localStorage.getItem('user')
    const [error, setError] = useState(false)
    const userObj = JSON.parse(user)
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const checkAvail = async (e) => {
        setNickName(e.target.value)
        var data = {
            nickName: e.target.value
        }
        var config = {
            method: "get",
            url: `${API_URL}/customers/nickname/is-available?nickName=${e.target.value}`,
            data: data,
        };
        axios(config)
            .then(function (response) {
                setError(false)
                setNickName(e.target.value)
            }).catch(function (error) {
                toast.error()
                if (error?.response?.data?.statusCode === 409) {
                    //   setNickName('')
                    setError(true)
                }
                // console.log('sdfasdf',error?.response?.data?.statusCode)/ 


            });
    }
    let nameUpdate = async () => {
        if (nickName) {
            setLoader(true)
            let tok = localStorage.getItem("accessToken");
            let data = {
                nickName: nickName
            }
            var config = {
                method: "patch",
                url: `${API_URL}/customers/profile`,
                data: data,
                headers: {
                    authorization: `Bearer ` + tok
                }
            };
            axios(config)
                .then(function (response) {
                    setLoader(false)
                    toast.success(response?.data?.message, toastDesign);
                    localStorage.setItem('userName', JSON.stringify(response?.data?.data))
                }).catch(function (error) {
                    setLoader(false)
                    //   if (error.code == 401) {
                    //     refreshToken()
                    //   }
                    toast.error(error?.response?.data?.message, toastDesign)
                });
        } else {
            toast.info('Name Input is Empty', toastDesign)
        }
    }
    useEffect(() => {
        setNickName(userObj?.nickName)
    }, [userObj?.nickName])

    return (
        <>
            {loader && <Loader />}
            <Navbar tNav={'tNav'} />
            <img src="\assets\pinkbg.svg" alt="img" className='img-fluid banner-bg' />
            <section className="refferal-section setting-secti">
                {/* <img src="\assets\bg-refferal.png" alt="img" className='img-fluid bg-refferal' /> */}
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h2>Settings</h2>
                        <p>Edit your nickname</p>
                    </div>
                    <div className="inner-box1">
                        <div className="left">
                            <div className="bottom-box">
                                <h6>Your display name</h6>
                                <div className="option-field">
                                    {/* <svg className='imgpen' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M16.625 17.417H2.375C2.05042 17.417 1.78125 17.1478 1.78125 16.8232C1.78125 16.4987 2.05042 16.2295 2.375 16.2295H16.625C16.9496 16.2295 17.2188 16.4987 17.2188 16.8232C17.2188 17.1478 16.9496 17.417 16.625 17.417Z" fill="#FF0083" />
                                        <path d="M15.0592 2.75466C13.5233 1.21882 12.0192 1.17924 10.4437 2.75466L9.48583 3.71257C9.40667 3.79174 9.375 3.91841 9.40667 4.02924C10.0083 6.12716 11.6867 7.80549 13.7846 8.40716C13.8162 8.41507 13.8479 8.42299 13.8796 8.42299C13.9667 8.42299 14.0458 8.39132 14.1092 8.32799L15.0592 7.37007C15.8429 6.59424 16.2229 5.84216 16.2229 5.08216C16.2308 4.29841 15.8508 3.53841 15.0592 2.75466Z" fill="#FF0083" />
                                        <path d="M12.3576 9.12837C12.128 9.01754 11.9063 8.90671 11.6926 8.78004C11.5184 8.67712 11.3522 8.56629 11.1859 8.44754C11.0513 8.36046 10.893 8.23379 10.7426 8.10712C10.7268 8.09921 10.6713 8.05171 10.608 7.98837C10.3468 7.76671 10.0538 7.48171 9.7926 7.16504C9.76885 7.14921 9.72927 7.09379 9.67385 7.02254C9.59468 6.92754 9.4601 6.76921 9.34135 6.58712C9.24635 6.46837 9.13552 6.29421 9.0326 6.12004C8.90593 5.90629 8.7951 5.69254 8.68427 5.47087C8.53895 5.15948 8.13025 5.06697 7.88726 5.30996L3.43552 9.76171C3.3326 9.86462 3.2376 10.0625 3.21385 10.1971L2.78635 13.2292C2.70718 13.7675 2.8576 14.2742 3.1901 14.6146C3.4751 14.8917 3.87093 15.0421 4.29843 15.0421C4.39343 15.0421 4.48843 15.0342 4.58343 15.0184L7.62343 14.5909C7.76593 14.5671 7.96385 14.4721 8.05885 14.3692L12.5179 9.91011C12.7561 9.67199 12.6666 9.26227 12.3576 9.12837Z" fill="#FF0083" />
                                    </svg> */}
                                    <input className={error && "inpuDisable"} value={nickName} onChange={checkAvail} type="text" placeholder='JohnDoe77' />
                                    {error && <p className="errorTxt">This nickname is already taken. Try a different one</p>}
                                </div>
                                <button disabled={error} onClick={nameUpdate} className={error ? "btn-save btnDisable" : "btn-save"}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Settings
